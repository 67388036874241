<template>
	<div class="pdpage">



		<div>
			<div class="prow" v-for="(item,idx) in DataList" :key="idx">
				<div style="min-height: 60px;" @click="showPrint(item)">
					<div class="picon">
						<img :src="'./images/logo.jpg'" />
					</div>
					<div class="pinfo">
						<div class="pname">{{item.device_id}}</div>
						<div class="pdesc">{{item.device_name?item.device_name:item.device_id}}</div>
						<div class="pstatus">
							<span style="color: cornflowerblue;">当前纸：{{item.now_paper}}</span>
							<span v-if="item.onstate" style="color: limegreen;">已启用</span>
							<span v-else style="color: orangered;">已禁用</span>
							<span>{{item.status_name}}</span>
						</div>
					</div>

				</div>

				<div class="chilun">
					<img :src="'./images/chilun.png'" />
				</div>

			</div>

			<div v-if="DataList.length==0" style="text-align: center;line-height: 200px;">还没有配置打印机</div>

		</div>




		<el-dialog :close-on-click-modal="false" title="打印机配置" :visible.sync="visibleModal" width="98%" top="5%">
			<el-form ref="ruleForm" v-model="printer" v-if="printer">
				<el-form-item label="打印机ID：" prop="title">
					<el-input disabled v-model="printer.device_id" style="width: 90%;"></el-input>
				</el-form-item>
				<el-form-item label="打印机名称：" prop="device_name">
					<el-input v-model="printer.device_name" style="width: 90%;"></el-input><span
						style="color: orangered;">*</span>
					<div style="color: blue; font-size: 12px; line-height: 20px;">打印机名称是别人在使用共享打印机时可以看到的名称,例如：二号打印机
					</div>
				</el-form-item>

				<!-- <el-form-item label="打印机型号：" prop="title">
						<el-input   v-model="printer.device_xh"></el-input>
						
				</el-form-item> -->
				<el-form-item label="当前纸类型：" prop="title">

					<el-radio-group v-model="printer.now_paper">
						<el-radio label="A4">A4</el-radio>
						<el-radio label="A3">A3</el-radio>
						<el-radio label="照片6寸">照片6寸</el-radio>
						<el-radio label="照片12寸">照片12寸</el-radio>

					</el-radio-group>
				</el-form-item>
				<el-form-item label="启用打印机：" prop="onstate">
					<el-radio-group v-model="printer.onstate">
						<el-radio :label="0">不启用</el-radio>
						<el-radio :label="1">启用</el-radio>
					</el-radio-group>
					<span style="color: orangered;">*</span>



				</el-form-item>
				<div style="margin-top: -20px;">
					<span style="color: orangered; font-size: 12px; margin-left: 20px;">
						不启用则不会使用此打印机打印
					</span>
				</div>


				<el-form-item label="自动双面打印：" prop="title">
					<el-radio-group v-model="printer.sm_print_auto">
						<el-radio :label="0">不支持</el-radio>
						<el-radio :label="1">支持</el-radio>

					</el-radio-group>


				</el-form-item>

				<el-form-item label="是否可彩打：" prop="title">
					<el-radio-group v-model="printer.color">
						<el-radio :label="0">只能黑白打印</el-radio>
						<el-radio :label="1">支持彩色打印</el-radio>

					</el-radio-group>


				</el-form-item>

				<el-form-item label="可打印纸张类型：" prop="title">

					<el-checkbox-group v-model="printer.paper_type">
						<el-checkbox label="A4">A4</el-checkbox>
						<el-checkbox label="A3">A3</el-checkbox>

					</el-checkbox-group>

				</el-form-item>
				<el-form-item label="是否可打印照片：" prop="title">
					<el-radio-group v-model="printer.printer_pic">
						<el-radio :label="0">不支持</el-radio>
						<el-radio :label="1">支持</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="可打印照片纸尺寸：" prop="title" v-if="printer.printer_pic">

					<el-checkbox-group v-model="printer.pic_type">
						<el-checkbox label="6寸">6寸</el-checkbox>
						<el-checkbox label="12寸">12寸</el-checkbox>

					</el-checkbox-group>

				</el-form-item>



				<div style="text-align: right;">
					<el-button size="small" @click="visibleModal = false" type="info" plain icon="el-icon-close">取消
					</el-button>
					<el-button size="small" @click="savePrint()" type="primary" icon="el-icon-check">保存设置</el-button>
				</div>

			</el-form>
		</el-dialog>


		<el-dialog :close-on-click-modal="false" title="快速开启打印机" :visible.sync="easyModalShow" width="800px">
			<el-form ref="ruleForm" label-width="150px" v-model="printer" v-if="printer">
				<el-form-item label="打印机ID：" prop="title">
					<el-input disabled v-model="printer.device_id" style="width: 80%;"></el-input>
				</el-form-item>

				<el-form-item label="当前纸类型：" prop="title">

					<el-radio-group v-model="printer.now_paper">
						<el-radio label="A4">A4</el-radio>
						<el-radio label="A3">A3</el-radio>
						<el-radio label="照片6寸">照片6寸</el-radio>
						<el-radio label="照片12寸">照片12寸</el-radio>

					</el-radio-group>
				</el-form-item>
				<el-form-item label="启用打印机：" prop="onstate">
					<el-radio-group v-model="printer.onstate">
						<el-radio :label="0">不启用</el-radio>
						<el-radio :label="1">启用</el-radio>
					</el-radio-group>
					<span style="color: orangered;">*</span>




				</el-form-item>



				<div style="text-align: right;">

					<el-button size="small" @click="savePrint2()" type="primary" icon="el-icon-check">保存</el-button>
				</div>

			</el-form>
		</el-dialog>



	</div>
</template>

<script>
	export default {
		data() {
			return {
				visibleModal: false,
				easyModalShow: false,
				DataList: [],
				printer: null
			}
		},
		mounted() {
			document.title="我的打印机"
			this.waitLogin().then(_=>{
				this.getList()
				this.timer = setInterval(()=>{
					this.refreshData()
				},3000)
			})
			
		},
		beforeUnmount() {
			clearInterval(this.timer)
		},
		
		
		methods: {
			getList() {
				this.$http.post("/api/shop_printer_list", {}).then(res => {

					this.DataList = res.data
				})
			},
			showPrint(e) {
				let p = JSON.parse(JSON.stringify(e))
				p.paper_type = p.paper_type ? p.paper_type.split(",") : ['A4'];
				p.pic_type = p.pic_type ? p.pic_type.split(",") : [];
				p.onstate ? p.onstate = 1 : p.onstate = 0;
				p.now_paper ? true : p.now_paper = 'A4'
				p.color ? true : p.color = 0
				p.sm_print_auto ? true : p.sm_print_auto = 0
				p.printer_pic ? true : p.printer_pic = 0
				p.device_name ? true : p.device_name = p.device_id
				this.printer = p
				this.visibleModal = true
				
			},
			savePrint() {
				if (!this.printer.device_name) {
					this.$message.error("请输入打印机名称")
					return
				}
				let p = JSON.parse(JSON.stringify(this.printer))
				p.paper_type = p.paper_type.join(",")
				p.pic_type = p.pic_type.join(",")
				console.log(p)
				this.$http.post("/api/set_print_info", p).then(res => {
					this.visibleModal = false
					this.$message.success("保存成功！")
					this.getList()
				})

			},
			showPrint2(e) {
				if (!e.device_name) {
					this.$message.error("请先完善打印机配置")
					return
				}
				let p = JSON.parse(JSON.stringify(e))
				p.paper_type = p.paper_type ? p.paper_type.split(",") : ['A4'];
				p.pic_type = p.pic_type ? p.pic_type.split(",") : [];
				p.onstate ? p.onstate = 1 : p.onstate = 0;
				p.now_paper ? true : p.now_paper = 'A4'
				p.color ? true : p.color = 0
				p.sm_print_auto ? true : p.sm_print_auto = 0
				p.printer_pic ? true : p.printer_pic = 0
				p.device_name ? true : p.device_name = p.device_id
				this.printer = p
				this.easyModalShow = true
			},
			savePrint2() {


				this.$http.post("/api/change_printer_paper", this.printer).then(res => {
					this.easyModalShow = false
					this.$message.success("保存成功！")
					this.getList()
				})

			},
		}
	}
</script>

<style scoped>
	.picon {
		width: 60px;
		height: 60px;
		border-radius: 8px;
		overflow: hidden;
		float: left;
	}

	.picon img {
		width: 100%;
		height: 100%;
	}

	.pinfo {
		margin-left: 70px;
	}

	.pname,
	.pdesc,
	.pstatus {
		height: 20px;
		line-height: 20px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.pstatus {
		font-size: 12px;
	}

	.pstatus span {
		padding: 0 5px;
	}

	.prow {
		margin-bottom: 10px;
		border-bottom: 1px solid #ccc;
		padding-bottom: 10px;
		position: relative;
	}

	.chilun {
		width: 20px;
		height: 20px;
		position: absolute;
		top: 20px;
		right: 20px;
	}

	.chilun img {
		width: 100%;
		height: 100%;
	}
</style>
<style>
	.el-radio {
		margin-right: 10px;
	}
	.el-dialog__body{padding: 10px !important;}
	.el-form-item{ margin-bottom: 10px !important;}
</style>
